<template>
  <div class="container pd100">
    <schedule v-model="active" :invitedList='list' @change="onScheduleChange" @refresh='refreshMonth' @toRefresh='refreshList' @refreshStudio="toRefreshStudio">
      <div v-if="itemList.length > 0" class="schedule-list">
        <div v-for="(item,index) in itemList" :key="index" :class="['schedule-list-item',item.intention_level == 9 ? 'schedule-list-item-bg':'schedule-list-item-bgf']">
          <div class="schedule-is-attend">
            <span v-if="item.is_attend == 1">已到访</span>
            <span v-else>未到访</span>
            <!-- <span v-if="item.is_attend == -1">鸽子</span> -->
          </div>
          <div class="schedule-list-item-nav">
              <div>{{item.promise_date_time}}</div>
          </div>
          <div class="schedule-list-item-info">
              <div>学员名称: {{item.customer_name}}</div>
              <!-- <div>联系电话: {{item.cellphone}}</div> -->
              <a :href="'tel:' + item.cellphone">联系电话: {{item.cellphone}}</a>
              <div>负责人: {{item.course_consultant_id}}</div>
              <div v-if="item.out_trade_no">订单号: {{item.out_trade_no}}</div>
              <div v-if="item.recent_contact_content" style="width:250px">最近跟进: {{item.recent_contact_content}}</div>
              <div v-if="item.remark" style="width:250px">备注: {{item.remark}}</div>
          </div>
        <van-button v-if="item.is_attend == 1" @click="toNoShow(item)" class="schedule-list-item-attend" size='small' type="primary">鸽子</van-button>
        <van-uploader class="schedule-list-item-attend" v-else :after-read="(file, detail)=>afterRead(file, detail,item)">
          <van-button class="schedule-list-item-attend-btn" size='small' type="primary">到访</van-button>
        </van-uploader>
        <van-button @click="toApply(item.task_id)" class="schedule-list-item-btn" size='small' type="primary">课程购买</van-button>
        </div>
      </div>
      <div v-else class="no-data">
        <img :src="no_order" class="no-data-image">
        <div class="no-data-title">暂无邀约到访T.T</div>
      </div>
    </schedule>
    <loadings :loading='loading' />
  </div>
</template>

<script>
import { Tabbar, TabbarItem, Calendar, Button, Dialog,Uploader } from 'vant'
import Schedule from './schedule'
import Loadings from '@/components/loading'
import PageMixin from '@/mixins/page'
import dayjs from 'dayjs'
import upload from '@/utils/upload'
import no_order from '@/assets/no_order.png'

export default {
  name: 'Timetable',
  components: {
    [Tabbar.name]: Tabbar,
    [TabbarItem.name]: TabbarItem,
    [Calendar.name]: Calendar,
    [Button.name]: Button,
    [Uploader.name]: Uploader,
    schedule: Schedule,
    loadings:Loadings
  },
  mixins: [PageMixin],
  data() {
    return {
      list: {},
      active: {date:dayjs().format('YYYY-MM-DD'),arr:[]},
      itemList: [],
      no_order,
      loading:false,
      activeBar:1,
      is_sub:0,
      studio_id:'',
    }
  },
  created() {
    if(localStorage.getItem("is_sub")){
        this.is_sub = localStorage.getItem("is_sub")
    }
    // this.onList()
  },
  
  methods: {
    onList() {
      this.loading = true
      let obj = {
          start_date:dayjs().startOf('month').format('YYYY-MM-DD'),
          end_date:dayjs().endOf('month').format('YYYY-MM-DD'),
          is_sub:this.is_sub,
          studio_id:this.studio_id,
      }
      this.$api.custom_huidong_invited(obj).then(res => {
        this.list = res.data
        this.loading = false
      })
    },
    afterRead(file, detail,item) {
      this.loading = true
      let that = this
      file.status = 'uploading'
      file.message = '上传中...'
      upload({ file: file.file })
        .then(res => {
          item.attendImg = res.fileName
          that.toAttend(item)
        })
        .catch(err => {
          console.error(err)
          file.status = 'failed'
          file.message = '上传失败'
        })
    },
    onScheduleChange(item) {
      this.itemList = item.arr
      this.active = item
    },
    toApply(id){
        this.$router.push({path:'/huidong/invited/teacherApply',query:{task_id:id}})
    },
    toNoShow(item){
      let that = this
      Dialog.confirm({
        title: '提示',
        message: '确认被鸽子??',
      })
        .then(() => {
            that.$api.custom_huidong_no_show({task_id:item.task_id}).then(res => {
              item.is_attend = -1
              that.$toast('操作成功~')
            })
        })
    },
    toAttend(item){
      let that = this
      that.$api.custom_huidong_attend({task_id:item.task_id,attend_images:[item.attendImg]}).then(res => {
        item.is_attend = 1
        this.loading = false
        that.$toast('操作成功~')
      })
    },
    refreshMonth(val){
      this.loading = val
    },
    refreshList(val){
      this.is_sub = val
      this.onList()
    },
    toRefreshStudio(val){
      this.studio_id = val
      this.onList()
    }
  }
}
</script>
<style lang="less" scoped>
  .schedule-list{
    min-height: 220px;
    padding: 10px 15px;
  }
  .schedule-is-attend{
    position: absolute;
    right: 10px;
  }
  @height:80px;
  .schedule-list-item{
    margin-bottom: 10px;
    border-radius: 4px;
    padding: 10px;
    position: relative;
    box-shadow:0 0 2px #999;
    &-nav{
        display: flex;
        justify-content:space-between;
        font-size: 18px;
    }
    &-info{
        margin-top: 10px;
        div{
            margin: 5px 0;
        }
    }
    &-btn{
        width: 68px;
        position: absolute;
        right: 10px;
        bottom: 10px;
    }
    &-attend{
        width: 68px;
        position: absolute;
        right: 10px;
        bottom: 46px;
        &-btn{
          width: 68px;
        }
    }
    &-noShow{
        width: 68px;
        position: absolute;
        right: 10px;
        bottom: 81px;
    }
  }
  .schedule-list-item-bg{
    background: rgb(220,20,60);
    color: #fff;
  }
  .schedule-list-item-bgf{
    background: #fff;
  }

  .no-data{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #fff;
    box-sizing: border-box;
    min-height: 220px;
    text-align: center;
    &-image{
      width: 96px;
      height: 96px;
      background: #fff;
      margin-bottom: 10px;
    }

  }
</style>

